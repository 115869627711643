// https://stackoverflow.com/questions/34607841/react-router-nav-bar-example
import React, { useState, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Util from "./shared/Util";
import logo from "./logo.svg";
import makeStyles from "@mui/styles/makeStyles";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setIsAdminSite,
  useLogoURL,
  useRegattaTitle,
} from "./shared/UseResults";
import { useSelectedRegatta } from "./shared/RegattaState";

setIsAdminSite(true);

const useStyles = makeStyles((/* theme */) => ({
  grow: {
    flexGrow: 1,
  },
  infoText: {
    marginRight: 8,
  },
  menuButton: {
    width: 40,
    alignSelf: "flex-end",
  },
  appBar: {
    color: "rgb(108, 125, 150)",
    backgroundColor: "#ffffff",
    padding: "8px",
    paddingTop: "2px",
    paddingBottom: "2px",
  },
  logo: {
    marginRight: 8,
  },
  toolbar: {
    padding: 0,
    minHeight: 40,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const Nav = () => {
  const [open, setOpen] = useState(false);
  const touchEl = useRef<any>();
  const navigate = useNavigate();
  const [regattaTitle] = useRegattaTitle();
  const [logoURL] = useLogoURL();
  const [regatta] = useSelectedRegatta();
  const location = useLocation();

  const title = regatta ? regattaTitle : "CrewTimer Race Admin";

  const handleLogout = () => {
    Util.doLogout();
  };

  const onGoHomeClick = () => {
    navigate("/", { replace: true });
  };

  const onTitleClick = () => {
    if (regatta && !location.pathname.startsWith("/manage")) {
      navigate(`/manage/${regatta}`, { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  };

  const handleClose = () => {
    setOpen(false);
    touchEl.current = undefined;
  };

  const handleOpen = (e) => {
    setOpen(true);
    touchEl.current = e.currentTarget;
  };

  const handleCloseAndGo = (url) => {
    handleClose();
    navigate(url);
  };

  const classes = useStyles();
  const printClass =
    location.pathname.startsWith("/qrcodes") ||
    location.pathname.startsWith("/payment")
      ? "noprint"
      : "";
  const email = (Util.isUserSignedIn() && Util.getUser()?.email) || "";
  return (
    <AppBar position="static" className={`${classes.appBar} ${printClass}`}>
      <Toolbar className={classes.toolbar}>
        <img
          className={classes.logo}
          src={logoURL ? logoURL : logo}
          alt="CrewTimer"
          width="auto"
          height="40"
          onClick={onGoHomeClick}
        />
        {regatta && (
          <Typography variant="h6" color="inherit" onClick={onTitleClick}>
            Manage -&gt;&nbsp;
          </Typography>
        )}
        <Typography variant="h6" color="inherit" className={classes.grow}>
          {title}
        </Typography>
        <div className={classes.grow}>
          {/* <div>
            CrewTimer mobile now supports&nbsp;
            <a href="https://mailchi.mp/a381aa8461c4/crewtimer-5_0-news" target="_blank" rel="noopener noreferrer">
              Bluetooth Clickers!
            </a>
          </div> */}
        </div>
        {email && (
          <Typography
            variant="subtitle1"
            color="inherit"
            className={classes.infoText}
            onClick={handleOpen}
          >
            {email}
          </Typography>
        )}
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={handleOpen}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="render-props-menu"
          anchorEl={touchEl.current}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleCloseAndGo("/help/QuickStart")}>
            Help
          </MenuItem>
          <MenuItem onClick={() => handleCloseAndGo("/store")}>Store</MenuItem>
          <MenuItem onClick={() => handleCloseAndGo("/privacy")}>
            Privacy Policy
          </MenuItem>
          <MenuItem onClick={() => handleCloseAndGo("/termsofuse")}>
            Terms of Use
          </MenuItem>
          <MenuItem onClick={() => handleCloseAndGo("/about")}>About</MenuItem>
          <MenuItem onClick={() => handleCloseAndGo("/contact")}>
            Contact
          </MenuItem>
          {Util.isUserSignedIn() && (
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          )}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};
export default Nav;
