import * as Names from './Names';
import firebase from 'firebase/compat/app';
import uuidgen from 'short-uuid';
import { KeyMap } from './KeyMap';
import {
  ReloadResult,
  Lap,
  RegattaConfig,
  RegattaInfo,
  TimeSystem,
  QRCodeInfo,
  ResultRegattaInfo,
} from './CrewTimerTypes';
import { Entry } from './Entry';
import { v1 as uuidv1 } from 'uuid';
import {
  N_BOW,
  N_CREW,
  N_CREW_ABBREV,
  N_DAY,
  N_EVENTNUM,
  N_EVENT_NAME,
  N_RACE_TYPE,
  N_RACE_TYPE_SPRINT,
  N_START,
  N_STROKE,
} from './Names';

export class Util {
  static authInitialized = false;
  static initialized = false;
  static firebase: firebase.app.App; // Couldn't figure our how to make this work with a type
  static regattaAdmins = {};
  static authCallbackList: ((user: firebase.User | undefined | null) => void)[] = [];
  static user: firebase.User | undefined | null;
  static isRegistered = false;
  static userSignedIn = false;

  static setFirebase(fire: any) {
    Util.firebase = fire as firebase.app.App;
  }

  static updateCrewAbbrev(entry: Entry) {
    let crewAbbrev = entry[Names.N_CREW_ABBREV];
    if (crewAbbrev) {
      // already set
      return;
    }
    crewAbbrev = entry[Names.N_CREW];
    if (!crewAbbrev) {
      // no crew name either
      return;
    }

    if (crewAbbrev.length > 5) {
      const names = crewAbbrev.split(' ');
      let buf = '';

      names.forEach((name) => {
        name = name.trim();
        if (name.length === 0) {
          return;
        }
        buf += name.substring(0, 1);
      });
      crewAbbrev = buf;
      if (crewAbbrev.length > 5) {
        crewAbbrev = crewAbbrev.substring(0, 5);
      }
    }
    entry[Names.N_CREW_ABBREV] = crewAbbrev;
  }

  static isDevSite() {
    return (Util.firebase.options as { projectId: string }).projectId.includes('dev');
  }

  static async isRegattaPresent(name: string) {
    name = name.replace('.', '');
    return Util.firebase
      .database()
      .ref('regatta/' + name + '/settings/config/Name')
      .once('value')
      .then((snapshot) => Boolean(snapshot.val()))
      .catch(() => false);
  }

  static async createRegattaId() {
    const idCountRef = Util.firebase.database().ref('global/idCounter');
    let idCount = 0;
    await idCountRef.transaction((value: number | null) => {
      idCount = (value || 12000) + 1;
      return idCount;
    });
    return `r${idCount}`;
  }
  static setUser(user: typeof Util.user) {
    if (user) {
      Util.user = user;
      Util.userSignedIn = true;
    } else {
      Util.user = undefined;
      Util.userSignedIn = false;
    }
  }

  // Return a promise with the regatta configuration.
  // Util.getRegattaConfig('ashtxatj').then(function(regattaConfig){
  //     console.log(JSON.stringify(regattaConfig));
  //   });
  static getRegattaConfig(name: string) {
    name = name.replace('.', '');
    return Util.firebase
      .database()
      .ref('regatta/' + name + '/settings/config')
      .once('value')
      .then((snapshot) => {
        const result = snapshot.val() as RegattaConfig;
        if (!result) return Promise.reject(`Regatta ${name} not found`);
        if (!result.PointsEngine) {
          result.PointsEngine = 'None'; // value may not have been set
        }
        // if (!result.Timezone) {
        //   result.Timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // }
        return Promise.resolve(result);
      })
      .catch((reason) => {
        console.log(`Read regatta ${name} error: ${reason instanceof Error ? reason.message : String(reason)}`);
        return Promise.reject(reason);
      });
  }

  // deprecated in favor of getLapDataOnce
  // static getLapData(regatta: string, eventId: string, bow: string | undefined, lapItem: (item: Lap) => void) {
  //   regatta = regatta.replace('.', '');
  //   if (!eventId && bow) {
  //     /** only filter by bow.  Use to search on '?' for unassigned times. */
  //     const refWild = Util.firebase.database().ref('regatta/' + regatta + '/lapdata');
  //     refWild
  //       .orderByChild('Bow')
  //       .equalTo(bow)
  //       .on('child_added', (snapshot) => {
  //         const result = snapshot.val() as Lap;
  //         // console.log("lap:",JSON.stringify(result));
  //         if (lapItem) {
  //           lapItem(result);
  //         }
  //       });
  //     return refWild;
  //   }

  //   const orderBy = bow ? 'EntryId' : 'EventNum';
  //   const value = bow ? `1-${eventId}-${bow}` : eventId;
  //   const ref = Util.firebase.database().ref('regatta/' + regatta + '/lapdata');
  //   ref
  //     .orderByChild(orderBy)
  //     .equalTo(value)
  //     .on('child_added', (snapshot) => {
  //       const result = snapshot.val() as Lap;
  //       // console.log("lap:",JSON.stringify(result));
  //       if (lapItem) {
  //         lapItem(result);
  //       }
  //     });
  //   return ref;
  // }

  static getLapDataOnce(regatta: string, eventId: string, bow: string | undefined, lapItem: (item: Lap) => void) {
    regatta = regatta.replace('.', '');
    if (!eventId && bow) {
      /** only filter by bow.  Use to search on '?' for unassigned times. */
      const refWild = Util.firebase.database().ref('regatta/' + regatta + '/lapdata');
      refWild
        .orderByChild('Bow')
        .equalTo(bow)
        .once('value')
        .then((snapshot) => {
          const result = snapshot.val() as Lap;
          // console.log("lap:",JSON.stringify(result));
          if (lapItem) {
            lapItem(result);
          }
        })
        .catch((reason) => {
          console.log(reason.error ? reason.error : String(reason));
        });
      return refWild;
    }

    const orderBy = bow ? 'EntryId' : 'EventNum';
    const value = bow ? `1-${eventId}-${bow}` : eventId;
    const ref = Util.firebase.database().ref('regatta/' + regatta + '/lapdata');
    ref
      .orderByChild(orderBy)
      .equalTo(value)
      .once('value')
      .then((snapshot) => {
        const result = snapshot.val() as Lap;
        // console.log("lap:",JSON.stringify(result));
        if (lapItem) {
          lapItem(result);
        }
      })

      .catch((reason) => {
        console.log(reason.error ? reason.error : String(reason));
      });
    return ref;
  }

  static getLapDataByBow(regatta: string, bow: string, lapItem: (item: KeyMap) => void) {
    regatta = regatta.replace('.', '');
    const ref = Util.firebase.database().ref('regatta/' + regatta + '/lapdata');
    ref
      .orderByChild('Bow')
      .equalTo(bow)
      .on('child_added', (snapshot) => {
        const result = snapshot.val() as KeyMap;
        // console.log("lap:",JSON.stringify(result));
        if (lapItem) {
          lapItem(result);
        }
      });
    return ref;
  }

  // Return a promise to set the regatta configuration
  // Util.setRegattaConfig('ashtxatj',regattaConfig).then(function(){
  // 	console.log("regatta stored");
  //   });

  /**
   * Return a promise to set the regatta configuration
   *
   * @param name
   * @param props A complete or subest of RegattaConfig
   */
  static setRegattaConfig(name: string, props: KeyMap) {
    name = name.replace('.', '');
    return Util.firebase
      .database()
      .ref('regatta/' + name + '/settings/config')
      .update(props);
  }

  static setGlobalTimingSystem(name: string, timingSystem: TimeSystem) {
    name = name.replace('.', '');
    return Util.firebase
      .database()
      .ref('regatta/' + name + '/settings/timeSystems/system')
      .update(timingSystem);
  }
  static setQRCodeInfo(qrCodeInfo: KeyMap<QRCodeInfo>, regattaId: string) {
    return Util.firebase
      .database()
      .ref('regatta/' + regattaId + '/settings/qrcodes')
      .update(qrCodeInfo);
  }

  static setEventTimingSystem(name: string, event: string, timingSystem: TimeSystem) {
    name = name.replace('.', '');
    return Util.firebase.database().ref(`regatta/${name}/settings/timeSystems/events/${event}`).update(timingSystem);
  }
  static storeLap(regatta: string, lap: Lap) {
    const updates = {};
    lap.src = 'web';
    lap.Timestamp = new Date().getTime();
    const pushId =
      Util.firebase
        .database()
        .ref('/regatta/' + regatta + '/journal')
        .push().key || (uuidv1() as string);

    updates['/regatta/' + regatta + '/lapdata/' + lap[Names.N_UUID]] = lap;
    updates['/journal/' + regatta + '/lapdata/' + pushId] = lap;
    return Util.firebase
      .database()
      .ref()
      .update(updates)
      .then(() => {
        // console.log('Sucessfully updated lap data');
      });
  }

  // Return a promise to delete the regatta
  static deleteRegatta(regatta: string) {
    regatta = regatta.replace('.', '');
    const updates = {};
    // console.log("info="+JSON.stringify(results.regattaInfo));
    updates['/regatta/' + regatta] = null;
    updates['/results/' + regatta] = null;
    updates['/rsummary/' + regatta] = null;
    updates['/asummary/' + regatta] = null;
    updates['/journal/' + regatta] = null;
    updates['/mobile/' + regatta] = null;
    return Util.firebase
      .database()
      .ref()
      .update(updates)
      .then(() => {
        // console.log('Regatta deleted: ', regatta);
        return Promise.resolve({ status: 'OK' });
      })
      .catch((reason) => {
        console.log('Delete regatta error: ', reason);
        return Promise.reject(reason);
      });
  }

  // Return a promise to clear the lap data
  static clearLapData(regatta: string, mobileOnly: boolean) {
    regatta = regatta.replace('.', '');
    const updates = {};
    // console.log("info="+JSON.stringify(results.regattaInfo));
    if (!mobileOnly) {
      updates['/regatta/' + regatta + '/lapdata'] = null;
    }
    // For testing, the ServerValue can be null
    // const ts = firebase.database.ServerValue ? firebase.database.ServerValue.TIMESTAMP : new Date().getTime();
    const ts = new Date().getTime();
    updates['/regatta/' + regatta + '/settings/config/ClearTS'] = ts;
    return new Promise<{ status?: string; error?: string }>((resolve, reject) => {
      console.log('clearing lap data for ' + regatta);
      Util.firebase
        .database()
        .ref()
        .update(updates)
        .then(() => {
          resolve({ status: 'OK' });
        })
        .catch((reason) => {
          console.log('Clear Lap Data error: ', reason);
          reject(reason);
        });
    });
  }

  static containsKey(map, key) {
    return !(typeof map[key] === 'undefined') || map[key] === null;
  }

  static refreshRegattaAdmins() {
    Util.firebase
      .database()
      .ref('groups/admins')
      .once('value')
      .then((snapshot) => {
        const result = snapshot.val();
        if (result) Util.regattaAdmins = result;
      })
      .catch((/* reason*/) => { });
  }

  static onRegattaResultsChange(regatta: string, onChange: (name: string, list: Lap[]) => void) {
    regatta = regatta.replace('.', '');
    const ref = Util.firebase.database().ref('regatta/' + regatta + '/lapdata');
    ref.on('value', (snapshot) => {
      const list = snapshot.val();
      onChange(regatta, list);
    });
    return ref;
  }

  static offRegattaResultsChange(ref) {
    ref.off();
  }

  // Register interest in changes to the summary regatta configuration
  static onRegattaSummaryChange(onChange) {
    const regattaListRef = Util.firebase.database().ref('asummary');
    regattaListRef.on('value', (snapshot) => {
      let summary = snapshot.val();
      if (!summary) summary = {};
      const email = Util.user?.email || '';

      Util.firebase
        .database()
        .ref('groups/admins')
        .once('value')
        .then((adminsnapshot) => {
          const result = adminsnapshot.val();
          if (result) Util.regattaAdmins = result;
          // Limit list to those the user has access to
          const allowed: KeyMap[] = [];
          Object.keys(summary).forEach((id) => {
            const regatta = summary[id];
            if (
              (Util.containsKey(Util.regattaAdmins, Util.user?.uid) && Util.regattaAdmins[Util.user?.uid || '']) ||
              regatta[Names.N_OWNER].indexOf(email) >= 0 ||
              (Util.containsKey(regatta, Names.N_ADMINS) && regatta[Names.N_ADMINS].indexOf(email) >= 0)
            )
              allowed.push(regatta);
          });
          /* Update React state when results change */
          onChange(allowed);
        })
        .catch((reason) => {
          console.log('Unable to read admin groups: ', reason);
        });
    });
    return regattaListRef;
  }

  static doLogout() {
    Util.firebase
      .auth()
      .signOut()
      .then(() => {
        console.log('Signed out');
        // Sign-out successful.
      })
      .catch((error) => {
        console.log('Error logging out: ', error);
        // An error happened.
      });
  }

  static isUserSignedIn() {
    return Util.userSignedIn === true;
  }

  static isUserRegistered() {
    return Boolean(Util.user && Util.isRegistered);
  }

  static isUserAdmin() {
    return Util.containsKey(Util.regattaAdmins, Util.user?.uid);
  }

  static getUser() {
    return Util.user;
  }

  static test() {
    Util.getRegattaConfig('ashtxatj')
      .then((regattaConfig) => {
        console.log('regatta read OK: ', JSON.stringify(regattaConfig));
        Util.setRegattaConfig('ashtxatj', regattaConfig)
          .then(() => {
            console.log('regatta stored');
          })
          .catch((reason) => {
            console.log('Regatta update failed:', reason);
          });
      })
      .catch((reason) => {
        console.log('regatta read fail: ', reason);
        return Promise.reject(reason);
      });
  }

  static delayedPromise(ms, work, ontimeout) {
    return new Promise((resolve, reject) => {
      // Set up the real work
      work(resolve, reject);

      // Set up the timeout
      setTimeout(() => {
        if (ontimeout) ontimeout();
        reject(`Promise timed out after ${ms} ms`);
      }, ms);
    });
  }

  // Return a promise to refresh the regatta csv spreadsheet
  static reloadCsv(regatta: string, url: string = ''): Promise<ReloadResult> {
    regatta = regatta.replace('.', '');
    const ref = Util.firebase.database().ref('actions/' + regatta + '/reloadcsv');
    const refUpdated = ref.child('updated');
    return Util.delayedPromise(
      60000,
      (resolve, reject) => {
        // read 'updated' value and wait for it to change
        return refUpdated.once('value').then((snapshot) => {
          let val = snapshot.val();
          if (!val) val = {};
          ref
            .update({ request: { url, date: new Date().toISOString() } })
            .then(() => {
              refUpdated.on('value', (snapvalue /* , prevChildKey*/) => {
                // console.log("csv update complete: ", snapshot.val(), " ", val, " ", prevChildKey);
                const update = snapvalue.val();
                if (update && val && val.date !== update.date) {
                  refUpdated.off();
                  resolve(update);
                }
              });
            })
            .catch((reason) => {
              console.log('reload csv fail: ', reason);
              refUpdated.off();
              reject(reason);
            });
        });
      },
      () => {
        refUpdated.off();
      }
    ) as Promise<ReloadResult>;
  }

  static _invokeAuthCallbacks() {
    for (const callback of Util.authCallbackList) {
      callback(Util.user);
    }
  }

  static onAuthStateChange(onChange: (typeof Util.authCallbackList)[0]) {
    Util.initializeAppUtilities();
    Util.authCallbackList.push(onChange);
  }

  // Return a promise to update the user registration
  static registerUser(props) {
    if (!Util.user) return Promise.reject(new Error('Not logged in'));
    console.log('Registering user ' + String(Util.user.email));
    props = Object.assign({}, props);
    props.uid = Util.user.uid;
    props.email = Util.user.email;
    props.displayName = Util.user.displayName;
    props.photoURL = Util.user.photoURL;
    // 		Util.firebase.database().ref('groups/admins').update({ x: true });
    return Util.firebase
      .database()
      .ref('users/' + Util.user.uid)
      .update(props)
      .then()
      .then((val) => {
        Util.isRegistered = true;
        Util._invokeAuthCallbacks();
        return Promise.resolve(val);
      })
      .catch((reason) => {
        return Promise.reject(reason);
      });
  }

  static isAuthInitialized() {
    return Util.authInitialized;
  }

  static initializeAppUtilities() {
    if (Util.initialized) return;
    Util.authInitialized = false;
    Util.initialized = true;
    Util.firebase.auth().onAuthStateChanged((user) => {
      Util.authInitialized = true;
      Util.setUser(user);
      if (user) {
        return Util.firebase
          .database()
          .ref(`users/${Util.user?.uid}`)
          .once('value')
          .then((snapshot) => {
            const profile = snapshot.val();
            Util.isRegistered = profile !== null;
            Util._invokeAuthCallbacks();
          })
          .catch((/* reason*/) => {
            Util._invokeAuthCallbacks();
          });
      } else {
        Util.isRegistered = false;
        Util._invokeAuthCallbacks();
      }
    });
  }

  static getResultWaypoints(regattaConfig: ResultRegattaInfo, forEditing = false) {
    let waypoints = regattaConfig[Names.N_RESULT_WAYPOINTS];
    if (forEditing || !waypoints || waypoints === '') {
      waypoints = regattaConfig[Names.N_WAYPOINTS];
    }
    if (!waypoints) {
      waypoints = '';
    }
    let waypointsList: string[] = [];
    if (waypoints.length !== 0) {
      waypointsList = waypoints.split(',');
      waypointsList = waypointsList.map((waypoint) => waypoint.trim());
      waypointsList = waypointsList.filter(
        (waypoint) => waypoint !== 'Start' && !waypoint.toLowerCase().startsWith('referee')
      );
    }

    if (!forEditing) {
      // Further prune list to remove 'backup' waypoints
      const all = ['Start', ...waypointsList, 'Finish'];
      waypointsList = waypointsList.filter((waypoint) => {
        if (waypoint.match(/[0-9]/)) {
          // has a number, consider pruning
          for (const wp of all) {
            // Prune if another waypoint starts with the same text
            if (waypoint !== wp && waypoint.startsWith(wp)) {
              return false;
            }
          }
        }
        return true;
      });
    }
    return waypointsList;
  }

  static getTimingWaypoints(regattaConfig: RegattaInfo): string[] {
    const waypoints = regattaConfig[Names.N_WAYPOINTS];
    if (!waypoints) return [];
    let waypointsList = waypoints.split(',');
    waypointsList = waypointsList.map((waypoint) => waypoint.trim());
    return waypointsList;
  }

  static async getUserConfig(uid: string) {
    return Util.firebase
      .database()
      .ref(`users/${uid}`)
      .once('value')
      .then((snapshot) => {
        const result = snapshot.val() as KeyMap;
        if (!result) return Promise.reject('User not found');
        return Promise.resolve(result);
      })
      .catch((reason) => {
        return Promise.reject(reason);
      });
  }
  static createEmptyRegatta(regatta: string) {
    const config: KeyMap = {};
    for (const field of Names.REGATTA_CONFIG_FIELDS) {
      config[field] = '';
    }
    config[Names.N_DATE] = new Date().toISOString().substring(0, 10);
    config[Names.N_NUM_DAYS] = '1';
    config[Names.N_NAME] = regatta;
    config[Names.N_CLOUD_KEY] = `${regatta}-${uuidgen.generate()}`;
    config[Names.N_MOBILE_PIN] = String(10000 + Math.trunc(Math.random() * 90000));
    config[Names.N_RACE_TYPE] = Names.N_RACE_TYPE_HEAD;
    config[Names.N_HANDICAP_TYPE] = Names.N_HANDICAP_NONE;
    config[Names.N_HANDICAP_NORMALIZED] = false;
    config[Names.N_HANDICAP_MULTIPLIER] = '1';
    config[Names.N_DATA_SOURCE] = Names.N_DATA_SOURCE_GOOGLE;
    config[Names.N_POINTS_ENGINE] = 'None';
    config[Names.N_RESULT_DIGITS] = '3';
    config[Names.N_FEE_TYPE] = 'Fee';
    config.Timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    config.ShowProgress = false;
    config[Names.N_DOC_URL] =
      'https://docs.google.com/spreadsheets/d/1K2UWYS9Vfb4HlHtMGRi5pGRWj4CM9ZrKNbWo58vdvh4/edit?usp=sharing';
    return config;
  }

  static async verifyAuth(email, uid) {
    if (uid === 'service') {
      return Promise.resolve({});
    }
    const user = await Util.getUserConfig(uid).catch(() => null);
    if (!user || user.uid !== uid || user.email !== email) {
      return Promise.reject(Error('Authentication failed'));
    }
    return Promise.resolve(user);
  }

  /**
   * Verify the email and uid tuple are allowed to admin the specified regatta
   * verifyAuth() should be called before this function is called to verify the
   * uid and email tuple is valid.
   *
   * @param email - The email address to check
   * @param uid - The uid associated with the email address
   * @param regatta - The regatta id to check
   *
   * @returns Promise that will resolve with regatta config on success, otherwise reject with Error.
   */
  static async verifyAuthForRegatta(email, uid, regatta) {
    const existing = await Util.getRegattaConfig(regatta);
    if (!existing) {
      return Promise.reject(Error(`Regatta ${regatta} not found`));
    }

    if (regatta === 'r12335') {
      // backdoor for Google to test the apps script add-on for sheets
      return Promise.resolve(existing);
    }

    email = email.toLowerCase();
    const authUser =
      email === existing[Names.N_OWNER].toLowerCase() || existing[Names.N_ADMINS]?.toLowerCase().includes(email);
    if (!authUser) {
      return Promise.reject(Error(`Email '${email}' is not an admin for the regatta`));
    }
    if (uid === existing[Names.N_UID] || uid === 'service') {
      return Promise.resolve(existing);
    }
    // Allow use of the doc id as a uid token
    if (uid.length > 10 && !uid.includes('/') && existing[Names.N_DOC_URL]?.includes(uid)) {
      return Promise.resolve(existing);
    }

    return Promise.reject(Error('Authentication failed'));
  }

  /**
   * Verify provided credentials allow access to the specified regatta.
   *
   * @param {*} email
   * @param {*} uid
   * @param {*} regatta
   *
   * @returns A Promise.resolve in call cases with either {status:'OK'} or {error:'message'}
   */
  static async validateCredentials(email: string, uid: string, regatta: string | undefined, op: string) {
    try {
      if (op === 'create') {
        regatta = undefined;
      }

      regatta = regatta?.replace('.', '');
      if (op !== 'refresh') {
        // refresh allows uid to be the document id for the spreadsheet
        await Util.verifyAuth(email, uid);
      }

      let config: undefined | RegattaConfig;
      if (regatta) {
        config = await Util.verifyAuthForRegatta(email, uid, regatta);
      }
      return { status: 'OK', config };
    } catch (err) {
      return { status: 'Fail', error: err instanceof Error ? err.message : String(err) };
    }
  }

  /**
   * Refresh regatta results via an http request that provides an
   * email address and uid for authentication.
   *
   * @param {*} email
   * @param {*} uid
   * @param {*} regatta
   *
   * @returns A Promise.resolve in call cases with either {status:'OK'} or {error:'message'}
   */
  static async refreshRegatta(email, uid, regatta) {
    try {
      regatta = regatta.replace('.', '');
      // await Util.verifyAuth(email, uid); // not used for refresh to allow apps-script use
      await Util.verifyAuthForRegatta(email, uid, regatta);
      const result = await Util.reloadCsv(regatta);
      return result;
    } catch (err) {
      return { error: err instanceof Error ? err.message : String(err) };
    }
  }

  /**
   * Clear regatta results via an http request that provides an
   * email address and uid for authentication.
   *
   * @param {*} email
   * @param {*} uid
   * @param {*} regatta
   *
   * @returns A Promise.resolve in call cases with either {status:'OK'} or {error:'message'}
   */
  static async clearRegatta(email, uid, regatta, mobileOnly) {
    try {
      regatta = regatta.replace('.', '');
      await Util.verifyAuth(email, uid);
      await Util.verifyAuthForRegatta(email, uid, regatta);
      const result = await Util.clearLapData(regatta, mobileOnly);
      return result;
    } catch (err) {
      return { error: err instanceof Error ? err.message : String(err) };
    }
  }

  /**
   * Delete a regatta via an http request that provides an
   * email address and uid for authentication.
   *
   * @param {*} email
   * @param {*} uid
   * @param {*} regatta
   *
   * @returns A Promise.resolve in call cases with either {status:'OK'} or {error:'message'}
   */
  static async removeRegatta(email, uid, regatta) {
    try {
      regatta = regatta.replace('.', '');
      await Util.verifyAuth(email, uid);
      await Util.verifyAuthForRegatta(email, uid, regatta);
      const result = await Util.deleteRegatta(regatta);
      return result;
    } catch (err) {
      return { error: err instanceof Error ? err.message : String(err) };
    }
  }

  static async createRegatta(uid, email, props, testregatta?: string) {
    const { title, RMRSID, date, info, test, url } = props;
    const user = await Util.getUserConfig(uid).catch(() => null);
    if (!user || user.uid !== uid || user.email !== email) {
      return { error: 'Authentication failed' };
    }
    let regatta: string = testregatta || '';
    if (RMRSID) {
      regatta = test ? `tst${RMRSID}` : `rm${RMRSID}`;
    }
    let existing;
    if (regatta) {
      existing = await Util.getRegattaConfig(regatta).catch(() => {
        return undefined;
      });
    }
    const configProps = {};
    Object.keys(props).forEach((name) => {
      if (Names.REGATTA_API_CONFIG_FIELDS.includes(name)) {
        configProps[name] = props[name];
      }
    });
    let config = {};
    if (existing) {
      if (uid !== existing[Names.N_UID] || email !== existing[Names.N_OWNER]) {
        return { error: 'Authentication failed. Not owner.' };
      }
      config = { ...existing, ...configProps };
    } else {
      if (!regatta) {
        regatta = await Util.createRegattaId();
      }
      config = { ...Util.createEmptyRegatta(regatta), ...configProps };
    }
    config[Names.N_TITLE] = title;
    if (info) {
      config[Names.N_INFO_TEXT] = info;
    }
    config[Names.N_OWNER] = email;
    config[Names.N_UID] = uid;
    config[Names.N_NAME] = regatta;
    config[Names.N_DATE] = date;
    config[Names.N_RMRSID] = String(RMRSID) || '';
    config[Names.N_DOC_URL] = url || '';
    config[Names.N_HANDICAP_TYPE] = props[Names.N_HANDICAP_TYPE] || '';
    config[Names.N_HANDICAP_MULTIPLIER] = props[Names.N_HANDICAP_MULTIPLIER] || 1;
    config[Names.N_HANDICAP_NORMALIZED] = props[Names.N_HANDICAP_NORMALIZED] || false;
    config[Names.N_POINTS_ENGINE] = props[Names.N_POINTS_ENGINE] || config[Names.N_POINTS_ENGINE] || 'None';

    // Insure DataSource has a value
    if (RMRSID) {
      config[Names.N_DATA_SOURCE] = Names.N_DATA_SOURCE_RM;
    } else if (!config[Names.N_DATA_SOURCE]) {
      config[Names.N_DATA_SOURCE] = Names.N_DATA_SOURCE_GOOGLE;
    }

    await Util.setRegattaConfig(regatta, config as RegattaConfig);
    await Util.reloadCsv(regatta);
    return {
      config: {
        mobileId: regatta,
        mobilePin: config[Names.N_MOBILE_PIN],
        status: 'OK',
      },
    };
  }

  static async createRMRegatta(title, RMRSID, uid, email, date, info, test = false) {
    return Util.createRegatta(uid, email, { title, RMRSID, date, info, test });
  }

  static normalizeWaypoint(name) {
    if (name === 'Start') {
      return 'S_time';
    } else if (name === 'Finish') {
      return 'F_time';
    } else {
      return `G_${name}_time`;
    }
  }

  /**
   * Create a 'CombinedEvents' entry for settings config
   *
   * @param {*} events A list of events to scan
   * @returns KeyMap<string[]>
   */
  static computeCombined(events: KeyMap[]) {
    const combined: KeyMap<string[]> = {};
    events.forEach((event) => {
      const combineWith = event[Names.N_COMBINE];
      if (combineWith) {
        const combineList = combined[combineWith] || [combineWith];
        const eventNum = event[Names.N_EVENTNUM];
        if (!combineList.includes(eventNum)) {
          combineList.push(eventNum);
        }
        combined[combineWith] = combineList;
      }
    });
    // if event 13 and 32 are combined with 5, also create entries for 13 combined with 5 and 32 etc.
    Object.values(combined).forEach((list) => list.forEach((eventNum) => (combined[eventNum] = list)));

    return combined;
  }

  static async getHeatsheet(regatta: string, fullname: boolean = false) {
    try {
      const result = await Util.firebase
        .database()
        .ref(`/results/${regatta}`)
        .once('value')
        .then((snapshot) => {
          const regattaData = snapshot.val();
          const crewAbbrevs: KeyMap<string> = {};
          const colsUsed = new Set<string>();
          const heatsheet: KeyMap<string>[] = [];
          let hasSprint = false;
          regattaData.results.forEach((row: KeyMap) => {
            let isSprint = row[N_RACE_TYPE] === N_RACE_TYPE_SPRINT;
            hasSprint = hasSprint || isSprint;

            const entries: KeyMap[] = row.entries;
            const eventInit: KeyMap = {};
            [N_DAY, N_EVENTNUM, N_START, N_EVENT_NAME].forEach((name) => {
              eventInit[name] = row[name];
            });
            entries.forEach((entry) => {
              // If we have numbers > 10, format as a head race.  Canoe/Kayak often have 10 lanes
              if (Number(entry[N_BOW] > 10 || isNaN(entry[N_BOW]))) {
                isSprint = false; // treat as head race
              }
              crewAbbrevs[entry[N_CREW_ABBREV]] = entry[N_CREW].replace(/ [A-Z]$/, ''); // remove seed 'A', 'B' etc from crew name
            });

            if (isSprint) {
              const event = { ...eventInit };
              entries.forEach((entry) => {
                const lane = `Lane ${entry[N_BOW]}`;
                colsUsed.add(lane);
                let seed = entry[N_CREW].replace(/^.*( [A-Z])$/, '$1');
                if (seed === entry[N_CREW]) {
                  seed = ''; // none found
                }
                // for fullname, add space to ; or / if not already present to allow
                // word wrapping to be effective when printing
                const crew = fullname
                  ? entry[N_CREW].replace(/[;|/](?=\S)/g, (match) => `${match} `)
                  : `${entry[N_CREW_ABBREV]}${seed}`;
                event[lane] = `${crew}${entry[N_STROKE] ? ` (${entry[N_STROKE]})` : ''}`;
              });
              heatsheet.push(event);
            } else {
              // A head race.  Collect up to 8 entries per line
              let laneNo = 1;
              let event = { ...eventInit };
              entries.forEach((entry) => {
                if (laneNo > 6) {
                  heatsheet.push(event);
                  laneNo = 1;
                  event = { ...eventInit, [N_EVENT_NAME]: '' };
                }
                const lane = `Lane ${laneNo++}`;
                colsUsed.add(lane);
                let seed = entry[N_CREW].replace(/^.*( [A-Z])$/, '$1');
                if (seed === entry[N_CREW]) {
                  seed = ''; // none found
                }
                const crew = fullname ? entry[N_CREW].replace(/;/g, ' ') : `${entry[N_CREW_ABBREV]}${seed}`;
                event[lane] = `${entry[N_BOW]} ${crew}${entry[N_STROKE] ? ` (${entry[N_STROKE]})` : ''}`;
              });
              heatsheet.push(event);
            }
          });
          const sortAlphaNum = (a: string, b: string) => a.localeCompare(b, 'en', { numeric: true });
          const colsToExport = [N_DAY, N_EVENTNUM, N_START, N_EVENT_NAME, ...Array.from(colsUsed).sort(sortAlphaNum)];
          const csv: string[][] = hasSprint ? [colsToExport] : [[N_EVENTNUM, N_START, N_EVENT_NAME]];
          heatsheet.forEach((event) => {
            if (event[N_EVENTNUM]) {
              csv.push(colsToExport.map((col) => event[col] || ''));
            }
          });

          csv.push([]);

          if (!fullname) {
            // Add a two column legend for crew abbrev
            csv.push(['', 'Abbrev', 'Name', '', 'Abbrev', 'Name', '', 'Abbrev', 'Name']);
            const crews = Object.keys(crewAbbrevs).sort(sortAlphaNum);
            const crewlegends = crews.map((name) => [name, crewAbbrevs[name]]);
            const third = Math.floor((crewlegends.length + 2) / 3);
            for (let i = 0; i < third; i++) {
              csv.push([
                '',
                ...crewlegends[i],
                '',
                ...(crewlegends[third + i] || []),
                '',
                ...(crewlegends[2 * third + i] || []),
              ]);
            }
          }
          return csv;
        });
      return result;
    } catch (e) {
      console.log(`Error generating heatsheet: ${e}`);
      return [] as string[][];
    }
  }
}

export default Util;
