import React, { useEffect, useState } from "react";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  Theme,
} from "@mui/material/styles";
import RegattaEdit from "./RegattaEdit";
import EventResults from "./shared/EventResults";
import About from "./shared/About";
import Privacy from "./shared/Privacy";
import TermsOfUse from "./shared/TermsOfUse";
import Store from "./shared/Store";
import FinishLynx from "./FinishLynx";
import Help from "./shared/Help";
import StoreComplete from "./StoreComplete";
import Contact from "./shared/Contact";
import Login from "./Login";
import Register from "./Register";
import Page404 from "./shared/Page404";
import Util from "./shared/Util";
import {
  Route,
  BrowserRouter as Router,
  Navigate,
  useLocation,
  Routes,
} from "react-router-dom";
import Nav from "./Nav";
import Markdown from "./shared/Markdown";
import { blue } from "@mui/material/colors";
import VersionPage from "./VersionPage";
import { setIsEditable } from "./shared/RegattaState";
import { TransactionLog } from "./shared/TransactionLog";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// eslint-disable-next-line import/no-unassigned-import
import "./App.css";
import KeyMap from "./shared/KeyMap";
import { Toast } from "./shared/Toast";
import ManageRegatta from "./ManageRegatta";
import { UrlTracker } from "./shared/UrlTracker";
import { ConfirmDialog } from "./shared/ConfirmDialog";
import { RegattaList } from "./RegattaList";
import QRCodes from "./QRCodes";
import { Payment } from "./payments/Payment";

// https://stackoverflow.com/questions/25646502/how-to-render-repeating-elements
// See sites/examples/ExamplesPage.js for how container is resized

// firebase deploy: https://www.codementor.io/yurio/all-you-need-is-react-firebase-4v7g9p4kf

// https://medium.com/@liangchun/using-react-toolbox-with-create-react-app-f4c2a529949

// material-ui with create-react-app: https://stackoverflow.com/questions/44192576/use-create-react-app-with-material-uihttps://github.com/firebase/functions-samples/tree/master/quickstarts/uppercase

// mobile detect: https://github.com/hgoebl/mobile-detect.js/

// themes: http://www.material-ui.com/#/customization/themes

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    primary: { main: "#007fff" },
    secondary: { main: blue[700] },
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: "30px",
        },
        head: {
          height: "30px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "2px 0.5em",
        },
        body: {
          fontSize: "1em",
        },
        head: {
          fontSize: "1em",
        },
      },
    },
  },
});

const API = () => {
  return <Markdown docId="CrewTimerAPI.md" />;
};

const App = () => {
  const [user, setUser] = useState<typeof Util.user | KeyMap>({});
  useEffect(() => {
    setIsEditable(true);
    Util.initializeAppUtilities();
    Util.onAuthStateChange((userval) => {
      setUser(userval);
    });
  }, []);

  // See https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <DndProvider backend={HTML5Backend}>
          <div
            className="App"
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <Router>
              <UrlTracker />
              <Nav />
              <ConfirmDialog />
              <div
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                <Routes>
                  <Route
                    path="/"
                    element={
                      <RequireAuth>
                        <RegattaList />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/results-edit/*"
                    element={
                      <RequireAuth>
                        <EventResults />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/list"
                    element={
                      <RequireAuth>
                        <RegattaList />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/edit/*"
                    element={
                      <RequireAuth>
                        <RegattaEdit />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/manage/*"
                    element={
                      <RequireAuth>
                        <ManageRegatta />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/qrcodes/*"
                    element={
                      <RequireAuth>
                        <QRCodes />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/log/*"
                    element={
                      <RequireAuth>
                        <TransactionLog />
                      </RequireAuth>
                    }
                  />
                  <Route path="/payment/*" element={<Payment />} />
                  <Route
                    path="/login"
                    element={<Login redirectToReferrer={user !== undefined} />}
                  />
                  <Route path="/register" element={<Register />} />
                  <Route path="/help/*" element={<Help />} />
                  <Route path="/api" element={<API />} />
                  <Route
                    path="/progression-engine"
                    element={<Navigate replace to="/help/Progressions" />}
                  />
                  <Route path="/finishlynx" element={<FinishLynx />} />
                  <Route path="/FinishLynx" element={<FinishLynx />} />
                  <Route path="/store-complete" element={<StoreComplete />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/store" element={<Store />} />
                  <Route path="/termsofuse" element={<TermsOfUse />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/version" element={<VersionPage />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route element={<Page404 />} />
                </Routes>
              </div>
            </Router>
            <Toast />
          </div>
        </DndProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

function RequireAuth({ children }) {
  const location = useLocation();
  if (Util.isUserRegistered()) {
    return children;
  } else if (Util.isAuthInitialized()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <></>;
}

export default App;
