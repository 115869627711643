//
export const N_REGATTA = 'regatta';
export const N_UUID = 'uuid';
export const N_CLOUD_KEY = 'CloudKey';
export const N_ENTRY_ID = 'EntryId'; // Flight-EventNum-Bow
export const N_EVENT_ID = 'EventId'; // Flight-EventNum
export const N_DAY = 'Day';
export const N_EVENT_NAME = 'Event';
export const N_EVENT_ABBREV = 'EventAbbrev';
export const N_EVENT_DISPLAY_ORDER = 'DispOrder';
export const N_EVENTNUM = 'EventNum';
export const N_EVENTINFO = 'EventInfo';
export const N_CREW = 'Crew';
export const N_CREW_ABBREV = 'CrewAbbrev';
export const N_CATEGORY = 'Category';
export const N_BOW = 'Bow';
export const N_ROSTER = 'Roster';
export const N_COMMENT = 'Comment';
export const N_STROKE = 'Stroke';
export const N_COX = 'Cox';
export const N_START = 'Start';
export const N_GATE = 'Gate';

export const N_EVENT_SOURCE = 'EventSource';
export const N_TIME = 'Time'; // HH:MM:SS.SSS
export const N_TIMESTAMP = 'Timestamp'; // UTC milli
export const N_INDEX = 'Index'; // results data storage index
export const N_STATUS = 'Status';
export const N_ENTRY_PROGRESS = 'P';
export const N_ENTRY_PROGRESS_STATE = 'S';
export const N_STATE = 'State'; // empty or 'Deleted'
export const N_SEQUENCE_NUM = 'SequenceNum';
export const N_PENALTY_TIME = 'PenaltyTime';
export const N_PENALTY_CODES = 'PenaltyCode';
export const N_PENALTY_LOCATION = 'PenaltyLocation';
export const N_PENALTY_LOCATION_CODE = 'PenaltyLocationCode';
export const N_PENALTY_QUANTITY = 'PenaltyQuantity';
export const N_PENALTY_SEVERITY = 'PenaltySeverity';
export const N_PENALTY_COMMENT = 'PenaltyComment';
export const N_PENALTY_CODE = 'PenaltyCode';
export const N_DOC_ATTACHMENTS = 'Docs';
export const N_REPORTER = 'Reporter';
export const N_UPDATE_SRC = 'src';
export const N_ADJUST_TIME = 'AdjustTime';
export const N_SPLIT = 'Delta';
export const N_HANDICAP = 'Handicap';
export const N_HANDICAP_CODE = 'HandicapCode';
export const N_EXHIBITION = 'Exhibition';
export const N_WAYPOINTS = 'Waypoints';
export const N_RESULT_WAYPOINTS = 'ResultWaypoints';
export const N_TIME_SYSTEMS = 'timeSystems';
export const N_OVERRIDE_WAYPPOINT = 'OverrideWaypoint';
export const N_RESULT_DIGITS = 'ResultDigits';
export const N_POINTS_ENGINE = 'PointsEngine';

export const N_PENALTY_LOCATIONS = 'PenaltyLocations';
export const N_DAY_LIST = 'DayList';
export const N_TIMED_FINAL_TIME = 'TF_Time';

export const STATE_DELETED = 'Deleted';

// synthetic derived fields
export const N_RAW_TIME = 'RawTime';
export const N_ADJ_TIME = 'AdjTime';
export const N_REF_ACTION = 'PenaltyCode';
export const N_PENALTY = 'Penalty';
export const N_PLACE = 'Place';
export const N_RELEGATED_PLACE = 'RelPlace';
export const N_PENALTY_TIME_MS = 'PenaltyTimeMs';
export const N_FINAL_TIME_MS = 'FinalTimeMs';

export const N_ADJUST_TIME_MS = 'AdjustTimeMs';
export const N_EVENT_KEY = 'eventKey';
export const N_AGE = 'Age';
export const N_OFFICIAL = 'Official';
export const N_OFFICIAL_TIME_MS = 'OfficialTimeMs';
export const N_TWEET = 'tweet';
export const N_START_TIME = 'S_time';
export const N_FINISH_TIME = 'F_time';

export const N_HAS_ROSTER = 'HasRoster';

// Special N_GATE values
export const N_GATE_TRACK = 'Trk';
export const N_GATE_PEN = 'Pen';
export const N_GATE_ADJ = 'Adj'; // Deprecated
export const N_GATE_REFEREE = 'R';
export const N_GATE_FINISH = 'F';
export const N_GATE_START = 'S';

// Special N_REF_ACTION values
export const N_REF_ACTION_OFFICIAL = 'Official';
export const N_REF_ACTION_FALSE_START = 'FalseStart';

// Column titles
export const N_ADJUST_TITLE = 'Adjust';
export const N_BOW_TITLE = 'Bow';
export const N_STROKE_COX = 'Stroke / Cox';
export const N_ADJ_TIME_TITLE = 'Time';
export const N_LANE_TITLE = 'Lane';

// Regatta Info fields
export const N_NAME = 'Name';
export const N_TITLE = 'Title';
export const N_LOGO_URL = 'LogoURL';
export const N_TITLES = 'Titles';
export const N_DATE = 'Date';
export const N_NUM_DAYS = 'NumDays';
export const N_MOBILE_PIN = 'MobileKey';
export const N_MOBILE_PINS = 'MobilePins';
export const N_DOC_URL = 'DocUrl';
export const N_INFO_TEXT = 'InfoText';
export const N_PENALTY_CODES_TITLE = 'Code';
export const N_FINISHED = 'Finished';
export const N_OWNER = 'Owner';
export const N_UID = 'uid';
export const N_ADMINS = 'Admins';
export const N_PUBLIC = 'Public';
export const N_ENTRIES = 'entries'; // List<ArgMap>
export const N_NUM_ENTRIES = 'NumEntries';
export const N_NUM_EVENTS = 'NumEvents';
export const N_CSV_TIMESTAMP = 'CsvTimestamp';
export const N_ENTRY_CSV = 'entryCsv';
export const N_RACE_TYPE = 'RaceType';
export const N_RACE_TYPE_SPRINT = 'Sprint';
export const N_RACE_TYPE_HEAD = 'Head';
export const N_HANDICAP_TYPE = 'HandicapType';
export const N_HANDICAP_MULTIPLIER = 'HandicapMultiplier';
export const N_HANDICAP_NORMALIZED = 'HandicapNormalized';
export const N_CUSTOM_CODES = 'CustomCodes';
export const N_CLEAR_TS = 'ClearTS';
export const N_RESULT_OMIT_COLS = 'ResultOmitCols';
export const N_TRACKING_STATIONS = 'TrackingStations';
export const N_JSON = 'json';
export const N_FEE_TYPE = 'FeeType';
export const N_PAYMENT = 'Payment';

// Twitter
export const N_TWITTER_TWEET_RESULTS = 'TweetResults';
export const N_TWITTER_AUTH_URL = 'TwitterAuthURL';
export const N_TWITTER_SCREEN_NAME = 'TwitterScreenName';
export const N_TWITTER_STATUS = 'TwitterStatus';
export const N_TWITTER_REQUEST_TOKEN = 'TwitterRequestToken';
export const N_TWITTER_ACCESS_TOKEN = 'TwitterAccessToken';
export const N_TWITTER_ACCESS_TOKEN_SECRET = 'TwitterAccessTokenSecret';

// Regatta Handicap Types
export const N_HANDICAP_NONE = 'None';
export const N_HANDICAP_MANUAL = 'Manual';
export const N_HANDICAP_US = 'US';
export const N_HANDICAP_RA = 'RA';
export const N_HANDICAP_CANADA = 'RCA';

export const N_HANDICAP_CANADA_GARRETT = 'RCA_GARRETT';

// Regatta Results ArgMap keys
export const N_EVENT_KEY_TO_NAME = 'eventKeyToName';
export const N_EVENT_LISTS = 'eventLists';
export const N_EVENT_INFO = 'eventInfo';
export const N_EVENT_ITEMS = 'eventItems'; // List<ArgMap> containing ordered list of entries
export const N_EVENT_INDEX = 'eventIndex'; // ordinal index for this event in eventItems
export const N_LAST_UPDATED_EVENT = 'lastUpdatedEvent';

// stored preferences
export const N_NAME_LIST = 'NameList';
export const N_REGATTA_LIST_NAME = 'RegattaList';
export const N_CLICKER_ID = 'ClickerId';
export const N_CLICKER_AUTH = 'ClickerAuth';
export const N_HORN_ID = 'HornId';
export const N_HORN_AUTH = 'HornAuth';
export const N_CLICKER_ENABLED = 'ClickerEnabled';
export const N_RESULTS_PENDING = 'ResultsPending';
export const N_RESULTS_PEND_OFFICIAL = 'ResultsPendOfficial';

export const N_COMBINE = 'Combine';
export const N_COMBINED_RACES = 'CombinedRaces';
export const N_FLIGHT_RACES = 'FlightRaces';
export const N_DATA_SOURCE = 'DataSource';
export const N_RM_PUBLISH_DISABLE = 'RMPublishDisable';
export const N_RMCONFIG = 'RMConfig';
export const N_DATA_SOURCE_GOOGLE = 'GoogleSheets';
export const N_DATA_SOURCE_RM = 'RegattaMaster';
export const N_DATA_SOURCE_LOCAL = 'Local';

// LapServlet fields
export const N_REGATTA_INFO = 'regattaInfo';
export const N_RESULTS = 'results';

// RegattaMaster
export const N_RMRSID = 'RMRSID';

export const N_STATION_TYPE = 'stationType';
export const N_STATION_TYPE_TIMING = 'Timing';
export const N_STATION_TYPE_PENALTIES = 'Penalties';
export const N_STATION_TYPE_TIMING_OR_PENALTIES = 'Timing+Penalties';

export const N_TZ_OFFSET = 'TZOffset';
export const N_SHOW_PROGRESS = 'ShowProgress';

export const PUBLIC_REGATTA_ATTRS = [
  N_NAME,
  N_OWNER,
  N_ADMINS,
  N_LOGO_URL,
  N_TITLE,
  N_TITLES,
  N_DATE,
  N_INFO_TEXT,
  N_FINISHED,
  N_HAS_ROSTER,
  N_RACE_TYPE,
  N_RESULT_OMIT_COLS,
  N_TRACKING_STATIONS,
  N_RESULTS_PEND_OFFICIAL,
  N_RESULTS_PENDING,
  N_NUM_ENTRIES,
  N_NUM_EVENTS,
  N_SHOW_PROGRESS,
  N_TZ_OFFSET,
  N_JSON,
];
export const CSV_PRIORITY_FIELDS = [
  N_DAY,
  N_RACE_TYPE,
  N_EVENT_NAME,
  N_CREW,
  N_STROKE,
  N_COMBINE,
  N_BOW,
  N_AGE,
  N_HANDICAP,
  N_HANDICAP_CODE,
];
export const NO_INHERIT_FIELDS = [
  N_CREW,
  N_CREW_ABBREV,
  N_COMBINE,
  N_EVENTINFO,
  N_HANDICAP,
  N_HANDICAP_CODE,
  N_PENALTY_CODES,
  N_PENALTY_CODES_TITLE,
  N_PENALTY_COMMENT,
  N_PENALTY_LOCATION,
  N_PENALTY_LOCATION_CODE,
  N_PENALTY_QUANTITY,
  N_PENALTY_TIME,
  N_PENALTY_TIME_MS,
  N_REPORTER,
  N_DOC_ATTACHMENTS,
  N_AGE,
  N_EXHIBITION,
  N_JSON,
];

/** These fields are modified by the admin regatta config form */
export const REGATTA_CONFIG_FIELDS = [
  N_NAME,
  N_DATE,
  N_NUM_DAYS,
  N_LOGO_URL,
  N_TITLE,
  N_TITLES,
  N_DOC_URL,
  N_FINISHED,
  N_HAS_ROSTER,
  N_PUBLIC,
  N_HANDICAP_TYPE,
  N_HANDICAP_MULTIPLIER,
  N_HANDICAP_NORMALIZED,
  N_CUSTOM_CODES,
  N_INFO_TEXT,
  N_MOBILE_PIN,
  N_MOBILE_PINS,
  N_RACE_TYPE,
  N_WAYPOINTS,
  N_RESULT_WAYPOINTS,
  N_RESULT_DIGITS,
  N_POINTS_ENGINE,
  N_PENALTY_LOCATIONS,
  N_OWNER,
  N_ADMINS,
  N_RESULT_OMIT_COLS,
  N_TRACKING_STATIONS,
  N_RESULTS_PEND_OFFICIAL,
  N_RESULTS_PENDING,
  N_DATA_SOURCE,
  N_RM_PUBLISH_DISABLE,
  N_RMRSID,
  N_COMBINED_RACES,
  N_SHOW_PROGRESS,
  N_TZ_OFFSET,
  N_JSON,
  N_FEE_TYPE,
];

// Fields that can be set via the management api
export const REGATTA_API_CONFIG_FIELDS = [
  N_DATE,
  N_NUM_DAYS,
  N_LOGO_URL,
  N_TITLE,
  N_TITLES,
  N_DOC_URL,
  N_FINISHED,
  N_PUBLIC,
  N_HANDICAP_TYPE,
  N_HANDICAP_MULTIPLIER,
  N_HANDICAP_NORMALIZED,
  N_CUSTOM_CODES,
  N_INFO_TEXT,
  N_MOBILE_PIN,
  N_MOBILE_PINS,
  N_RACE_TYPE,
  N_WAYPOINTS,
  N_RESULT_WAYPOINTS,
  N_RESULT_DIGITS,
  N_POINTS_ENGINE,
  N_PENALTY_LOCATIONS,
  N_RESULT_OMIT_COLS,
  N_TRACKING_STATIONS,
  N_RESULTS_PEND_OFFICIAL,
  N_RESULTS_PENDING,
  N_DATA_SOURCE,
  N_SHOW_PROGRESS,
  N_TZ_OFFSET,
  N_ADMINS,
  N_JSON,
  N_FEE_TYPE,
];

// ServerUtils fields
export const N_IS_USER_ADMIN = 'isUserAdmin';
export const N_IS_USER_LOGGED_IN = 'isUserLoggedIn';
export const N_USER_EMAIL = 'UserEmail';
export const N_ALL_EVENTS = 'All Events';
export const N_CREWTIMER_REMOTE_ENABLE = 'crewtimerRemoteEnable';

export const N_SCRATCH = 'Scratch';
export const N_DQ = 'DQ';
export const N_EXCLUDE = 'EXC';

// penalties
export const N_DID_NOT_START = 'DNS';
export const N_DID_NOT_FINISH = 'DNF';

export const N_SERVICE_USER_LIST = 'UserList'; // Query / Set user info
export const N_USER = 'User';
export const N_OP_GET_USERS = 'GetUsers';
export const N_OP_UPDATE_USER = 'UpdateUser';
export const N_OP_VALIDATE_USER = 'ValidateUser';
export const N_OP_GET_REGATTA_NAMES = 'GetRegattaNames';
export const N_OP_GET_REGATTA_RESULTS = 'getResults';

export const N_OPERATION = 'op';

// User levels
export const N_USER_LEVEL = 'UserLevel';
export const N_USER_LEVEL_GUEST = 0;
export const N_USER_LEVEL_NORMAL = 1;
export const N_USER_LEVEL_ADMIN = 1000;

// Cookie names
export const N_COOKIE_PAGE = 'page';

// Database Global Table Names
export const N_TABLE_REGATTALIST = 'RegattaList';
export const N_TABLE_CHANNELS = 'Channels';
export const N_TABLE_TWITTER_REQUEST_TOKEN = 'TwitterRequestToken';

// Database Regatta Table Names
export const N_TABLE_LAPDATA = 'LapData';
export const N_TABLE_TRANSACTION_LOG = 'TransactionLog';
export const N_TABLE_TWEETS = 'Tweets';

/** *********** Mobile Keys ****************************** */
export const N_BOW_LABEL = 'BowLabel';
export const N_MODIFIED = 'Modified';

export const N_STATUS_TX_PEND = 'TxPend';
export const N_STATUS_TX_ACTIVE = 'TxActive';
export const N_STATUS_TX_FAIL = 'Fail';
export const N_STATUS_TX_OK = 'OK';
export const N_CONFIG_SOURCE = 'configSource';
export const N_CONFIG_SOURCE_LOCAL = 'Local';
export const N_CONFIG_SOURCE_CREWTIMER = 'CrewTimer.com';
export const K_INITIAL_TS = -1;

// Settings storage keys
export const N_SETTING_WAYPOINT = 'waypoint';
