import {
  PayPalButtons,
  PayPalButtonsComponentProps,
  PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import { FunctionComponent, useState } from "react";
import { InvoiceItem } from "./PaymentTypes";
import { PayPalButtonOnApprove } from "@paypal/paypal-js/types/components/buttons";
import { Box } from "@mui/material";

const payPalOptions = {
  // clientId:
  //   "AbgmhhRzZvZXqjx9efuICxWfETws7f9f25h_9Sw-41c9RiA9Q04ofqfBs9mPMBq2tlDfoTt8hDZcocBG", // sandbox
  clientId:
    "ARuThLxjWtW0xblltrxPzU7Bz73fzMYIFjMgYEPAZ0Tbs3wOKupyQGzGiDCH9P-BO4D96aW0mdgxdgpG", // live

  currency: "USD",
  intent: "capture",
  components: "buttons",
  disableFunding: "paylater",
};

export interface PayPalPaymentProps {
  cloudKey: string;
  items: InvoiceItem[];
  onApprove: PayPalButtonOnApprove;
}

export const PayPalPayment: FunctionComponent<PayPalPaymentProps> = ({
  cloudKey,
  items,
  onApprove,
}) => {
  const [resultsError, setResultsError] = useState("");
  const createOrder = async () => {
    try {
      // console.log(`sending ${JSON.stringify({ cloudKey, items })}`);
      setResultsError("");
      const response = await fetch(
        "https://crewtimer.com/api/create-paypal-order",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            cloudKey,
            items,
            clientId: payPalOptions.clientId,
          }),
        }
      );

      const result = await response.json();

      // console.log(`result = ${JSON.stringify(result)}`);

      if (!(result?.status === "OK")) {
        throw new Error(`${result?.message}`);
      }

      if (!result?.order?.id) {
        const errorDetail = result.order.details[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${result.order.debug_id})`
          : "Unexpected error occurred, please try again.";

        throw new Error(errorMessage);
      }

      return result.order.id;
    } catch (error) {
      const msg = error instanceof Error ? error.message : String(error);
      console.error(msg);
      setResultsError(msg);
    }
  };

  const styles: PayPalButtonsComponentProps["style"] = {
    label: "pay",
  };
  return (
    <PayPalScriptProvider options={payPalOptions}>
      {resultsError && <Box sx={{ color: "red" }}>{resultsError}</Box>}
      <PayPalButtons
        createOrder={createOrder}
        onApprove={onApprove}
        style={styles}
      />
    </PayPalScriptProvider>
  );
};
