import { Invoice } from "./Invoice";
import { InvoiceItem } from "./PaymentTypes";
import Util from "../shared/Util";
import { CircularProgress, Typography } from "@mui/material";
import { useFirebaseDatum } from "../shared/UseFirebase";
import { useLocation } from "react-router-dom";

const vendor = {
  name: "CrewTimer Regatta Timing",
  email: "info@crewtimer.com",
  street: "3227 139th Ave SE",
  city: "Snohomish",
  state: "WA",
  zip: "98290",
};

const purchaser = {
  name: "John Smith",
  email: "",
  street: "",
  city: "",
  state: "",
  zip: "",
};

const singleItem: InvoiceItem = {
  name: "",
  description: "Timing Fee",
  quantity: 343,
  priceEach: 0.5,
  total: 0,
  feeType: 'Fee',
  selected: true,
};
export const Payment = () => {
  const location = useLocation();
  const user = Util.getUser();

  const parts = location.pathname.split("/");
  const regattaId = parts.length >= 3 ? parts[2] : "xyzzy-no-valid-id";
  const [summary, summaryQueried] = useFirebaseDatum(`/rsummary/${regattaId}`);
  const [regattaConfig, regattaConfigQueried] = useFirebaseDatum(`/regatta/${regattaId}/settings/config`);  // requires 'login' for /payment/<id>

  if (!summaryQueried || !regattaConfigQueried) {
    return <CircularProgress />;
  } else if (!summary) {
    return <Typography variant="h4">
      Unable to load data for '{regattaId}'
    </Typography>
  }

  // Derived values
  const numEntries = summary?.NumEntries || 0;
  const date = summary?.Date || "2025-01-01";
  singleItem.quantity = numEntries;
  singleItem.name = regattaId;
  singleItem.description = summary.Title;
  singleItem.feeType = singleItem.quantity < 10 ? 'Free' : (summary.FeeType || 'Fee');

  purchaser.name = user?.displayName || "";
  purchaser.email = user?.email || "";

  let cloudKey = regattaConfig?.CloudKey;
  if (!cloudKey) {
    if (parts.length >= 4) {
      cloudKey = parts[3]; // extract from URL /payment/<regatta>/<cloudKey>
    } else {
      if (regattaConfig) {
        // default before they were created when regatta created
        cloudKey = `${regattaId}-9AY9v6gf2jiE2bzV8bbkta`;
      } else {
        // Unable find cloudKey
        return <CircularProgress />
      }
    }
  }

  return (
    <Invoice
      logoUrl="https://dev.crewtimer.com/static/media/logo.4ccd3d01842dbd5c1245f94e17b2f8a8.svg" // Replace with your own logo URL
      vendor={vendor}
      purchaser={purchaser}
      invoiceDate={date}
      invoiceNumber={regattaId}
      cloudKey={cloudKey}
      items={[singleItem]}
    />
  );
};
